// 房源详情
<template>
  <div class="housing">
    <div class="housing_nav p-t-20 p-b-20 w-1200 margin-auto">
      <div class="housing_nav_top">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">房源管理 </el-breadcrumb-item>
          <el-breadcrumb-item>租房</el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
          <el-breadcrumb-item class="color_blue">在线签约</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--sign-container start-->
    <div class="sign-container p-t-20 p-b-20 margin-auto">
      <div class="w-1200 margin-auto">
        <div class="sign-t">房屋交易协议书</div>
        <div v-if="details && details.content" class="sign-article" v-html="details.content">

        </div>
        <div class="sign-bot p-t-40 p-b-40">
          <el-button class="sign-btn" @click="signature">确定</el-button>
        </div>
      </div>
    </div>
    <!--sign-container end-->
    <!-- 上传信息 S -->
    <upload-pop ref="pop" @succeed="returnTop"></upload-pop>
    <!-- 上传信息 E -->
    <signature ref="signature" @succeed="succeed"></signature>
    <select-client ref="client" @succeed="returnTop"></select-client>
    <AnnexListPop></AnnexListPop>
    <CommissionPop></CommissionPop>
    <InventoryPop></InventoryPop>
  </div>
</template>

<script>
import UploadPop from '@/components/UploadPop.vue';
import Signature from '@/components/Signature.vue';
import SelectClient from '@/components/SelectClient.vue';
import AnnexListPop from '@/components/AnnexListPop.vue';
import CommissionPop from '@/components/CommissionPop.vue';
import InventoryPop from '@/components/InventoryPop.vue';
export default {
  components: { UploadPop, Signature, SelectClient, AnnexListPop, CommissionPop, InventoryPop },
  data() {
    return {
      id: '',//
      img: '',//签名图片
      type: 1,//
      details: {}
    };
  },
  methods: {
    //返回上一级
    returnTop() {
      this.$router.go(-1)

    },
    uploadMessage(data) {
      this.$refs.client.open(data)
    },
    signature() {
      if (!this.details) {
        this.$message({
          message: '请上传合同信息',
          type: 'warning'
        });
        return
      }
      this.$refs.pop.open(this.details.number, this.id)
      // this.img ? this.$refs.pop.open(this.type,this.details.number,this.img,this.id) : this.$refs.signature.open()
    },
    succeed(img) {
      this.img = img
      this.$refs.pop.open(this.type, this.details.number, this.img, this.id)
    },
    getContant() {
      this.$axios.contract({ contract_type: this.type }).then(res => {
        this.details = res.data
      })
    }
  },
  created() {
    this.type = this.$route.query.type
    this.id = this.$route.query.id
    this.getContant()
  }
};
</script>

<style lang="less" scoped>
.sign-container {
  max-width: 1400px;
  background: #fff;

  .sign-t {
    font-size: 24px;
    text-align: center;
  }

  .sign-btn {
    width: 640px;
    height: 56px;
    border-radius: 8px;
    background: #3273f6;
    color: #fff;
    font-size: 18px;
  }

  .sign-bot {
    display: flex;
    justify-content: center;
  }
}</style>
